
.el-int{
  width: 250px;
  margin-left: 20px;
}
.activeClass {
  background: #2338e6;
  color: #ffffff;
}

.company-contain {
  display: flex;
  flex-direction: column;
  background: #f1f0fe;

  .head-box {
    background: #fff;
  }

  .category-list {
    display: flex;
    padding-top: 20px;
    margin: 0 20px 20px;
    border-bottom: 1px solid #dcdfe6;

    .cate-left {
    }

    .cate-content {
      width: 1%;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;

      .cate-item {
        padding: 0 8px;
        margin: 0 0 10px 12px;
        border: 1px solid transparent;
        cursor: pointer;

        &.current {
          border-color: #1e33e3;
          color: #1e33e3;
        }
      }
    }
  }

  .time-box {
    display: flex;
    align-items: center;
    .day-type {
      margin-right: 30px;
    }
    .radio-group {
      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #2338e6;
        border-color: #2338e6;
      }

      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
        color: #fff;
      }

      ::v-deep .el-radio-button__inner:hover {
        color: #2338e6;
      }
    }
  }
}

.el-table {
  margin-top: 20px;
  flex: 1;
  height: 1%;
  min-height: calc(100vh - 460px);
  ::v-deep .el-table__body-wrapper {
    height: calc(100vh - 350px) !important;
  }
}

.page-content {
  background: #fff;
}

.right-row-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.filter-box {
  display: flex;
  // margin-bottom: 20px;

  .filter-item {
    margin-left: 20px;

    .light-title {
      color: #a4a4a4;
    }
  }
}

.operation-content {
  display: flex;
  justify-content: center;
}

.scroll-hint {
  padding: 12px;
  font-size: 14px;
  text-align: center;
}
